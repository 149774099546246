// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wayfinder[data-v-29a19c08] {
  width: 100%;
  height: 100%;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.container-way[data-v-29a19c08] {
  min-height: 214px;
  height: 100%;
  display: flex;
  gap: 25px;
  padding-inline: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #253444;
  overflow: hidden;
  margin-bottom: 2px;
}
.container-way.remaining[data-v-29a19c08] {
  margin-bottom: -1.3px;
  min-height: 214px;
  height: 100%;
}
.empty-wayfinder[data-v-29a19c08] {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: 0.5px;
}
.container-icon[data-v-29a19c08] {
  min-width: 164.3px;
  max-width: 164.3px;
  height: 164.3px;
  padding: 30px;
  display: flex;
  place-items: center;
  place-content: center;
}
.container-icon svg[data-v-29a19c08] {
  width: 104.1px;
  height: 104.1px;
}
.container-icon img[data-v-29a19c08] {
  width: 100%;
  height: 100%;
}
.container-text[data-v-29a19c08] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-stretch: normal;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.container-text span[data-v-29a19c08] {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.container-text span[data-v-29a19c08]:nth-child(1) {
  font-size: 45.2px;
  font-weight: 600;
  line-height: 1.23;
}
.container-text span[data-v-29a19c08]:nth-child(2) {
  font-size: 28.7px;
  font-weight: normal;
  line-height: 1.14;
}
.component[data-v-29a19c08] {
  font-size: 28.7px;
  line-height: 1.29;
  letter-spacing: 1.03px;
  text-align: center;
  margin-top: 15px;
  padding: 0px 15px;
  border-radius: 10.3px;
  background-color: #00af8b;
  width: unset !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
